body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type="date"]{
  height: 23px;
  border:dotted 1px rgb(175, 170, 170)
}

.MuiFormLabel-root{
    color:rgb(97, 94, 94) !important
}

.desc-detalles{
  background:#b7cdd5;
  padding:5px;
  border:dotted 1px rgb(175, 170, 170)
}
